.container {
  max-width: 130rem;
  margin: 6rem auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;

  @media only screen and (max-width: 46.875em) {
    flex-direction: column-reverse;
    gap: 3rem;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding-left: 2rem;
    padding-right: 2rem;

    h2 {
      border-left: 3px solid var(--primary);
      margin-left: -13px;
      padding-left: 1rem;
      font-size: 3rem;
      background-image: var(--gredient);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
    .headerAr {
      border-left: none;
      padding-left: 0;
      margin-left: 0;

      margin-right: -13px;
      padding-right: 1.3rem;
      border-right: 3px solid var(--primary);
    }
    p {
      font-size: 2rem;
      line-height: 1.3;
      letter-spacing: 1px;
    }
    h3 {
      margin-top: 1rem;
      font-size: 2rem;
      font-weight: 700;
    }

    .about {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      font-size: 1.6rem;
      font-weight: 700;

      a {
        color: var(--primary10);
        background-color: var(--bg-btn);
        cursor: pointer;
        margin-top: 1rem;
        justify-self: start;
        padding: 1rem 2rem;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: var(--primary90);
          color: var(--primary20);
        }
      }
      span {
        color: var(--secondary);

        &:nth-child(5) {
          grid-column: 1/-1;
        }
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 46.875em) {
      grid-row: 1;
    }

    img {
      width: 85%;

      @media only screen and (max-width: 46.875em) {
        width: 60%;
      }
    }
  }
}
