.toggle-container {
  z-index: 10000;
  display: flex;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 53.125em) {
    margin-right: 8rem;
  }

  @media only screen and (max-width: 25em) {
    gap: 1rem;
  }
}
.ar {
  display: flex;
  flex-direction: row-reverse;
  gap: 2rem;

  @media only screen and (max-width: 53.125em) {
    margin-left: 8rem;
  }

  @media only screen and (max-width: 25em) {
    gap: 1rem;
  }
}

.lang {
  z-index: 10000;
  cursor: pointer;
  background: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
  padding: 0.5rem 0.8rem;
}

.toggle {
  z-index: 10000;
  position: relative;

  cursor: pointer;
  height: 3rem;
  width: 5rem;
  border-radius: 50px;
  background-image: linear-gradient(aqua, skyblue);
}

.day {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: yellow;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  box-shadow: 0 0 5px yellow;
  z-index: 1;
  transition: all 0.3s ease;
}

.shape {
  position: absolute;
  background: whitesmoke;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.sm {
  height: 0.2rem;
  width: 1rem;
  top: 45%;
  left: 60%;
}

.md {
  height: 0.3rem;
  width: 2rem;
  top: 25%;
  left: 35%;
  z-index: 2;
}

.lg {
  height: 0.5rem;
  width: 2.6rem;
  bottom: 0.7rem;
  left: 20%;
}

.night {
  background-image: linear-gradient(midnightblue, rebeccapurple);
}
.night > .day {
  background: whitesmoke;
  box-shadow: 0 0 5px whitesmoke;
  transform: translate(2rem, 0);
}

.night .sm {
  height: 0.2rem;
  width: 0.2rem;
  transform: translate(-1rem, 0.2rem);
}

.night .sm:first-of-type {
  transform: translate(-1.8rem, -0.5rem);
}
.night .md {
  height: 0.3rem;
  width: 0.3rem;
  transform: translate(0.1rem, 0.1rem);
}
.night .lg {
  height: 0.35rem;
  width: 0.35rem;
  transform: translate(0.3rem, -0.3rem);
}

.night .shape {
  background-color: lightgray;
  box-shadow: 0 0 10px 2px violet;
}
