.container {
  margin: 0;
  padding: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  transition: all 0.6s ease;
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;

  &.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .bg {
    background: rgba(255, 255, 255, 0.26);
    backdrop-filter: blur(7.2px);
    -webkit-backdrop-filter: blur(7.2px);
    height: 100vh;
    width: 100%;
  }

  .modal {
    background: linear-gradient(to right, #4286f4, #373b44);
    padding: 4rem;
    max-width: 80rem;
    width: 90%;
    height: 50rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
    border-radius: 6px;

    @media only screen and (max-width: 46.875em) {
      max-width: 70rem;
      height: 50rem;
      padding: 4rem 2rem;
    }
    @media only screen and (max-width: 43.75em) {
      max-width: 50rem;
      height: 50rem;
      padding: 1rem 4rem;
    }

    @media only screen and (max-width: 28.125em) {
      padding: 1rem 3rem;
    }
    @media only screen and (max-width: 25em) {
      padding: 4rem;
    }

    h2 {
      color: var(--primary);
      font-size: 4rem;
    }

    .modal-container {
      width: 100%;
      display: flex;
      gap: 2rem;
    }
    .icon-container {
      flex: 1;
      position: relative;

      @media only screen and (max-width: 43.75em) {
        display: none;
      }

      .icon {
        width: 100%;
        height: 100%;
        fill: var(--primary);
      }
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      flex: 1;

      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.6rem;

        label {
          font-size: 2rem;
          font-weight: 700;
          color: var(--primary);
        }

        input,
        textarea {
          text-align: center;
          outline: none;
          border: none;
          border-radius: 6px;
          padding: 1rem 2rem;
          width: 100%;
        }
        textarea {
          height: 8rem;
        }
      }

      button {
        padding: 1rem 2rem;
        background-color: var(--primary90);
        transition: all 0.3s ease;
        width: 50%;
        border-radius: 5px;

        &:hover {
          background-color: var(--bg-btn);
        }
      }
    }

    .close {
      cursor: pointer;
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 4rem;
    }
  }
}

input[type="email"] {
  direction: ltr;
}
