.container {
  max-width: 130rem;
  margin: 4rem auto 0;
}

.header {
  text-align: center;
  font-weight: 700;
}

.cardsContainer {
  padding: 4rem;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48rem, 1fr));

  @media only screen and (max-width: 31.25em) {
    grid-template-columns: repeat(auto-fit, minmax(45rem, 1fr));
  }
  @media only screen and (max-width: 27em) {
    grid-template-columns: repeat(auto-fit, minmax(38rem, 1fr));
    align-items: center;
    justify-content: center;
  }

  column-gap: 4rem;
  row-gap: 6rem;
  justify-items: center;
}
