.container {
  border-top: 1px solid var(--primary);

  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  h3 {
    font-size: 1.6rem;
  }

  .btn {
    background: none;
  }
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  border-top: 2px dashed var(--primary);
  padding-top: 1rem;
}

.social {
  color: var(--bg-btn);
  width: 3rem;
  transition: all 0.3s;
  height: 100%;

  &:hover {
    color: var(--primary);
    transform: scale(1.1);
  }

  @media only screen and(min-width:93.75em) {
    width: 4rem;
  }
}
