.cardContainer {
  position: relative;
  height: 32rem;
  width: 38rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);

  &:hover {
    .card {
      background-position: bottom;
    }
  }

  .card {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top;
    transition: background-position 5s ease-in-out;
    z-index: 10000;

    &:hover + .details {
      opacity: 0;
    }
  }

  .details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.7);
    transition: all 1s ease-in-out;

    h2 {
      color: white;
      font-size: 2.4rem;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;

      a {
        color: white;
        font-size: 1.4rem;
        border: 1px solid white;
        padding: 0.5rem 1rem;

        &:nth-child(1) {
          background-color: var(--bg-btn);
        }
      }
      .disable {
        cursor: not-allowed;
      }
    }
  }
}
